
import { Vue, Component } from 'vue-property-decorator'
  @Component({
    name: 'CenterSearchHeader'
  })
export default class extends Vue {
    private searchKeyword = ''
    private dateRangeValue: Date[] = []
    private options = [
      {
        value: '稽查未通过',
        label: 'fail'
      },
      {
        value: '未稽查',
        label: 'ready'
      },
      {
        value: '稽查通过',
        label: 'pass'
      }
    ]

    private startPoints = [{
      value: '1',
      label: '1',
      disabled: false
    }, {
      value: '2',
      label: '2',
      disabled: false
    }, {
      value: '3',
      label: '3',
      disabled: false
    }, {
      value: '4',
      label: '4',
      disabled: false
    }, {
      value: '5',
      label: '5',
      disabled: false
    }]

    private startValue = ''
    private finishValue = ''
}
