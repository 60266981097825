
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import DepositInformation from '@/views/center/components/checkResult/depositInformation.vue'
import transactionInformation from '@/views/center/components/checkResult/transactionInformation.vue'
import sellerInformation from '@/views/center/components/checkResult/sellerInformation.vue'
import buyerInformation from '@/views/center/components/checkResult/buyerInformation.vue'
import substrateInformation from '@/views/center/components/checkResult/substrateInformation.vue'
import assestInformation from '@/views/center/components/checkResult/assestInformation.vue'
import Constant from '@/constant/constant'
import fetchGetAuditOrdersQuery from '@/api/husuraApi/getAuditOrdersApi'
import { IAuditOrdersList, IGetAuditOrdersQuery } from '@/api/types'

@Component({
  name: 'ApplyResultDrawer',
  computed: {
    Constant() {
      return Constant
    }
  },
  components: { DepositInformation, transactionInformation, sellerInformation, buyerInformation, substrateInformation, assestInformation }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        superviseInfo: {}
      }
    }
  }) private initData!: {
    superviseInfo: IGetAuditOrdersQuery
  }

  private statusList = [
    'ready',
    'ready',
    'ready',
    'ready',
    'ready',
    'ready'
  ]

  private superviseInfo = {}
  private auditOrdersList: IAuditOrdersList[]= []

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.superviseInfo = this.initData.superviseInfo
      this.fetchApplyResultQuery(this.initData.superviseInfo.id)
    }
  }

  private fetchApplyResultQuery(exchangeOrderSummaryId: number) {
    fetchGetAuditOrdersQuery(exchangeOrderSummaryId)
      .then(({ data: res }) => {
        const { data } = res
        this.auditOrdersList = data.audit_orders
        this.statusList[0] = data.audit_orders[0].bottom_asset_check_result ? 'pass' : 'fail'
        this.statusList[1] = data.audit_orders[0].asset_right_check_result ? 'pass' : 'fail'
        this.statusList[2] = data.audit_orders[0].existence_check_result ? 'pass' : 'fail'
        this.statusList[3] = data.audit_orders[0].order_check_result ? 'pass' : 'fail'
        this.statusList[4] = data.audit_orders[0].seller_check_result ? 'pass' : 'fail'
        this.statusList[5] = data.audit_orders[0].buyer_check_result ? 'pass' : 'fail'
      })
      .catch(error => {
        console.error(error)
      })
  }

  private close(refresh = false) {
    this.$emit('close', refresh)
  }
}
